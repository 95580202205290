<template>
  <tr :class="bgRow">
    <td>
      <b-form-group
        id="input-group-buildings"
        label=""
        label-for="input-buildings"
      >
        <treeselect
          v-model="form.building_id"
          :multiple="false"
          placeholder="Pilih Gedung"
          :options="buildings"
          @input="selectBuilding"
        />
        <small class="text-danger">{{ error.building_id }}</small>
      </b-form-group>
    </td>
    <td>
      <b-form-group id="input-group-days" label="" label-for="input-days">
        <treeselect
          v-model="form.day_id"
          :multiple="false"
          placeholder="Pilih Hari"
          :options="days"
          @input="selectDay"
        />
        <small class="text-danger">{{ error.day_id }}</small>
      </b-form-group>
    </td>
    <td>
      <b-form-group id="input-group-times" label="" label-for="input-times">
        <treeselect
          v-model="form.time_id"
          :multiple="true"
          placeholder="Pilih Jam"
          :options="times"
          @input="selectTime"
        />
        <small class="text-danger">{{ error.time_id }}</small>
      </b-form-group>
    </td>
    <td>
      <b-form-group
        id="input-group-teachers"
        label=""
        label-for="input-teachers"
      >
        <treeselect
          v-model="form.teacher_id"
          :multiple="true"
          placeholder="Pilih Dosen"
          :options="teachers"
          @input="selectTeacher"
        />
        <small class="text-danger">{{ error.teacher_id }}</small>
      </b-form-group>
    </td>
    <td>
      <b-form-group
        id="input-group-class_types"
        label=""
        label-for="input-class_types"
      >
        <treeselect
          v-model="form.class_type_id"
          :multiple="false"
          placeholder="Pilih Kelas"
          :options="class_types"
          @input="selectClassType"
        />
        <small class="text-danger">{{ error.class_type_id }}</small>
      </b-form-group>
    </td>
    <td>
      <b-button
        size="sm"
        class="btn-circle btn-outline-danger"
        v-b-tooltip.hover
        title="Hapus"
        @click="deleteAttribute()"
        ><i class="fas fa-trash px-0"></i
      ></b-button>
    </td>
  </tr>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    index: Number,
    buildings: Array,
    days: Array,
    times: Array,
    teachers: Array,
    class_types: Array,
    formProps: Object,
    bgRow:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // form: {
      //   building_id: 0,
      //   day_id: 0,
      //   time_id: [],
      //   teacher_id: 0,
      //   class_type_id: 0,
      // },
      form: this.formProps,
      error: {
        building_id: "",
        day_id: "",
        time_id: "",
        teacher_id: "",
        class_type_id: "",
      },
      //   buildings: [],
      //   days: [],
      //   times: [],
      //   teachers: [],
      //   class_types: [],
    };
  },
  methods: {
    setAttribute() {
      this.$emit("setAttribute", this.form, this.index);
    },
    deleteAttribute() {
      this.$emit("deleteAttribute", this.index);
    },
    selectBuilding(evt) {
      if (typeof evt == "undefined") {
        this.form.building_id = "";
      }
      this.setAttribute();
    },
    selectDay(evt) {
      if (typeof evt == "undefined") {
        this.form.day_id = "";
      }
      this.setAttribute();
    },
    selectTime(evt) {
      if (typeof evt == "undefined") {
        this.form.time_id = "";
      }else{
        this.form.time_id.sort()
      }
      this.setAttribute();
    },
    selectTeacher(evt) {
      if (typeof evt == "undefined") {
        this.form.teacher_id = "";
      }else{
        this.form.teacher_id.sort()
      }
      this.setAttribute();
    },
    selectClassType(evt) {
      if (typeof evt == "undefined") {
        this.form.class_type_id = "";
      }
      this.setAttribute();
    },
    async getBuildingOption() {
      let response = await module.setTreeSelect("api/buildings");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.buildings = response.data;
        this.buildings.unshift({
          label: "Pilih Gedung",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getDayOption() {
      let response = await module.setTreeSelect("api/days");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.days = response.data;
        this.days.unshift({
          label: "Pilih Hari",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTimeOption() {
      let response = await module.setTreeSelect("api/times");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.times = response.data;
        this.times.unshift({
          label: "Pilih Jam",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        `api/users?role_id=1&page_size=500`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getClassTypeOption() {
      let response = await module.setTreeSelect("api/class-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_types = response.data;
        this.class_types.unshift({
          label: "Pilih Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },
  },
  mounted() {
    // this.getBuildingOption();
    // this.getDayOption();
    // this.getTimeOption();
    // this.getClassTypeOption();
    // this.getTeacherOption();
  },
  watch:{
    formProps: function (newVal, oldVal) {
      console.log('formProps berubah', this.formProps)
      this.form = this.formProps;
    },
  }
};
</script>

<style>
</style>