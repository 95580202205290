<template>
  <v-app>
    <div class="row justify-content-center">
      <!-- <b-button @click="testToast">klik</b-button> -->
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-1"
              id="kt_wizard_v1"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Body-->
              <div
                class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
              >
                <div class="col-xl-12">
                  <div>
                    <form class="form" @submit.stop.prevent="formOnsubmit()">
                      <!--begin: Wizard Step 1-->
                      <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <h5
                          class="
                            font-weight-bolder
                            text-dark
                            font-size-h6 font-size-h3-lg
                          "
                        >
                          <span>
                            {{ title }}
                          </span>
                        </h5>

                        <b-form-group
                          id="input-group-subject"
                          label="Kode/Mata kuliah/SKS:"
                          label-for="input-subject"
                        >
                          <b-form-input
                            id="input-subject"
                            v-model="display.subject_name"
                            placeholder="Pilih Kode/Mata kuliah/SKS"
                            readonly
                            @click="$bvModal.show('modal-subject')"
                          ></b-form-input>
                          <small class="text-danger">{{
                            error.subject_id
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-study-program"
                          label="Program Studi:"
                          label-for="input-study-program"
                        >
                          <treeselect
                            v-model="form.study_program_id"
                            :multiple="false"
                            placeholder="Pilih Program Studi"
                            :options="study_programs"
                          />
                          <small class="text-danger">{{
                            error.study_program_id
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-semester"
                          label="Semester:"
                          label-for="input-semester"
                        >
                          <treeselect
                            v-model="form.semester_id"
                            :multiple="false"
                            placeholder="Pilih Semester"
                            :options="semesters"
                          />
                          <small class="text-danger">{{
                            error.semester_id
                          }}</small>
                        </b-form-group>

                        <b-form-group id="input-group-capacity">
                          <label for="input-capacity">Kapasitas:</label>
                          <b-form-input
                            id="input-capacity"
                            v-model="form.capacity"
                            placeholder="Kapasitas"
                          ></b-form-input>
                          <small class="text-danger">{{
                            error.capacity
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-school-year"
                          label="Angkatan:"
                          label-for="input-school-year"
                        >
                          <treeselect
                            v-model="form.class"
                            :multiple="false"
                            placeholder="Pilih Angkatan"
                            :options="school_years"
                          />
                          <small class="text-danger">{{ error.class }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-lecture_status"
                          label="Status Perkuliahan:"
                          label-for="input-lecture_status"
                        >
                          <treeselect
                            v-model="form.lecture_status"
                            :multiple="false"
                            placeholder="Pilih Status Perkuliahan"
                            :options="subject_groups"
                          />
                          <small class="text-danger">{{
                            error.lecture_status
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-meeting_statuses"
                          label="Status Pertemuan:"
                          label-for="input-meeting_statuses"
                        >
                          <treeselect
                            v-model="form.meeting_status"
                            :multiple="false"
                            placeholder="Pilih Status Pertemuan"
                            :options="meeting_statuses"
                          />
                          <small class="text-danger">{{
                            error.meeting_status
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-subject_types"
                          label="Jenis Mata Kuliah:"
                          label-for="input-subject_types"
                        >
                          <treeselect
                            v-model="form.subject_type"
                            :multiple="false"
                            placeholder="Pilih Jenis Mata Kuliah"
                            :options="subject_types"
                          />
                          <small class="text-danger">{{
                            error.subject_type
                          }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-semester_groups"
                          label="Kelompok Semester:"
                          label-for="input-semester_groups"
                        >
                          <treeselect
                            v-model="form.semester_group_id"
                            :multiple="false"
                            placeholder="Pilih Kelompok Semester"
                            :options="semester_groups"
                          />
                          <small class="text-danger">{{
                            error.semester_group_id
                          }}</small>
                        </b-form-group>

                        <div
                          class="
                            row
                            no-gutters
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div>
                            <span class="font-wight-bold h5"
                              >Gedung/Ruang, Hari, Jam, Dosen, Kelas</span
                            >
                          </div>
                          <div>
                            <!-- <b-button variant="success" squared>Tambah</b-button> -->
                          </div>
                        </div>
                        <div class="row no-gutters mt-3">
                          <table class="table">
                            <tr>
                              <th>Gedung</th>
                              <th>Hari</th>
                              <th>Jam</th>
                              <th>Dosen</th>
                              <th>Kelas</th>
                              <th></th>
                            </tr>
                            <template v-for="(attribute, i) in form.attributes">
                              <FormAttribute
                                :key="i"
                                :index="i"
                                :buildings="buildings"
                                :days="days"
                                :times="times"
                                :teachers="teachers"
                                :class_types="class_types"
                                :formProps="form.attributes[i]"
                                :bgRow="attribute.bgRow"
                                @setAttribute="setAttribute"
                                @deleteAttribute="deleteAttribute"
                              />
                            </template>
                          </table>
                        </div>
                        <div class="row no-gutters">
                          <b-button
                            variant="success"
                            squared
                            @click="addAttributes"
                            size="sm"
                            ><i class="fas fa-plus"></i> Tambah</b-button
                          >
                        </div>

                        <!-- <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group> -->
                      </div>
                      <!--end: Wizard Step 1-->

                      <!--begin: Wizard Actions -->
                      <div
                        class="d-flex justify-content-between border-top pt-10"
                      >
                        <div>
                          <b-button
                            type="submit"
                            variant="primary"
                            :disabled="isConflict ? true : false"
                            >Simpan</b-button
                          >
                          <b-button
                            type="button"
                            class="ml-2"
                            variant="default"
                            @click="$router.push('/schedules/list')"
                          >
                            Batal
                          </b-button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </form>
                  </div>

                  <ModalSubject @chooseData="chooseDataSubject" />
                </div>
              </div>
              <!--end: Wizard Body-->
            </div>
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body">
            <h5
              class="font-weight-bolder text-dark font-size-h6 font-size-h5-lg"
            >
              <span> Informasi Konflik Jadwal </span>
            </h5>
            <div class="row">
              <div class="col">
                <b-table
                  bordered
                  hover
                  :items="itemBuilding"
                  :fields="fieldBuilding"
                  class="mt-3"
                  responsive
                >
                  <template #table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" />
                  </template>
                  <template #cell(room_name)="data">
                    {{ data.item.building_name + " - " + data.item.room_name }}
                  </template>
                  <template #cell(times)="data">
                    <span
                      v-for="item in data.item.times"
                      :key="item.id"
                      :class="item.is_conflict ? 'bg-danger' : ''"
                      class="d-block"
                      style="white-space: nowrap"
                    >
                      {{ item.start_time }} - {{ item.end_time }}
                    </span>
                  </template>
                </b-table>
              </div>
              <div class="col">
                <!-- <h5
                class="
                  font-weight-bolder
                  text-dark
                  font-size-h6 font-size-h5-lg
                "
              >
                <span> Informasi Konflik Jadwal Dosen </span>
              </h5> -->

                <b-table
                  bordered
                  hover
                  :items="itemTeacher"
                  :fields="fieldTeacher"
                  class="mt-3"
                  responsive
                >
                  <template #table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" />
                  </template>
                  <template #cell(number)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(times)="data">
                    <span
                      v-for="item in data.item.times"
                      :key="item.id"
                      :class="item.is_conflict ? 'bg-danger' : ''"
                      class="d-block"
                      style="white-space: nowrap"
                    >
                      {{ item.start_time }} - {{ item.end_time }}
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalSubject from "@/view/components/general/ModalSubject.vue";
import FormAttribute from "@/view/components/schedules/FormAttribute.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  components: {
    ModalSubject,
    FormAttribute,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        capactity: "",
        study_program_id: "",
        semester_id: "",
        lecture_status: "",
        meeting_status: "",
        subject_type: "",
        semester_group_id: "",
      },
      study_programs: [],
      semesters: [],
      school_years: [],
      semester_groups: [],
      subject_groups: [
        { id: "", label: "Pilih Kelompok", isDisabled: true },
        { id: "1", label: "Program Studi" },
        { id: "2", label: "Fakultas" },
        { id: "3", label: "Universitas" },
      ],
      meeting_statuses: [
        { id: "", label: "Pilih Status Pertemuan", isDisabled: true },
        { id: "1", label: "Tatap Muka" },
        { id: "2", label: "Non Tatap Muka" },
      ],
      subject_types: [
        { id: "", label: "Pilih Jenis Mata Kuliah", isDisabled: true },
        { id: "1", label: "Wajib" },
        { id: "2", label: "Pilihan" },
      ],
      menuDate1: false,
      menuDate2: false,
      display: {
        subject_name: "",
      },
      // existing
      itemBuilding: [],
      fieldBuilding: [
        {
          key: "room_name",
          label: "Gedung/Ruang",
        },
        {
          key: "day_name",
          label: "Hari",
        },
        {
          key: "times",
          label: "Waktu",
        },
        {
          key: "subject_name",
          label: "Mata Kuliah",
        },
      ],
      itemTeacher: [],
      fieldTeacher: [
        {
          key: "teacher_names",
          label: "Dosen",
        },
        {
          key: "day_name",
          label: "Hari",
        },
        {
          key: "times",
          label: "Waktu",
        },
        {
          key: "subject_name",
          label: "Mata Kuliah",
        },
      ],
      //   form attribute
      buildings: [],
      days: [],
      times: [],
      teachers: [],
      class_types: [],
      scheduleAttributeDeleted: [],
      isConflict: false,
      isDuplicate: false,
    };
  },
  methods: {
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if ( response != null) {
        this.form.semester_id = response.id
        console.log('seme', this.form.semester_id)
      }else{
        console.log('null')
      }

      console.log('run sermester active')
    },
    testToast() {

      this.$toast("I'm a toast!");
      this.$toast("Default toast");
      this.$toast.info("Info toast");
      this.$toast.success("Success toast");
      this.$toast.error("Error toast");
      this.$toast.warning("Warning toast");
    },
    checkTimeSelected(time1, time2) {
      for (let a = 0; a < time1.length; a++) {
        for (let b = 0; b < time2.length; b++) {
          if (time1[a] == time2[b]) {
            return true;
          }
        }
      }

      return false;
    },
    checkTeacherSelected(teacher1, teacher2) {
      for (let a = 0; a < teacher1.length; a++) {
        for (let b = 0; b < teacher2.length; b++) {
          if (teacher1[a] == teacher2[b]) {
            return true;
          }
        }
      }

      return false;
    },
    checkTimeDuplicate(value, index){
      let isDuplicate = false
      for (let d = 0; d < this.form.attributes.length; d++) {
          let attr = this.form.attributes[d];
          if (d != index) {
            if (
              attr.building_id == value.building_id &&
              attr.day_id == value.day_id &&
              this.checkTimeSelected(attr.time_id, value.time_id) == true
            ) {
              this.$toast.error("Duplikat input ruang pada jam yang sama");
              this.form.attributes[index].bgRow = "table-danger";
              this.isConflict = true;
              // this.isDuplicate = true;
              this.form.attributes[index].is_duplicate = true
              isDuplicate = true
              // throw new Error("duplicate");
              break
            }else{
                if(!isDuplicate){
                  this.form.attributes[index].is_duplicate = false
                }
            }
          }else if(d == index){
            if(!isDuplicate){
                  // this.form.attributes[index].is_duplicate = false
                  // this.form.attributes[index].bgRow = "";
                }
          }
        }
    },
    checkTeacherDuplicate(value, index){
      let isDuplicate = false
      for (let d = 0; d < this.form.attributes.length; d++) {
          let attr = this.form.attributes[d];
          if (d != index) {
            if (
              attr.day_id == value.day_id &&
              this.checkTimeSelected(attr.time_id, value.time_id) == true &&
              this.checkTeacherSelected(attr.teacher_id, value.teacher_id) ==
                true
            ) {
              this.form.attributes[index].bgRow = "table-danger";
              this.$toast.error("Duplikat input dosen pada jam yang sama");
              this.isConflict = true;
              // this.isDuplicate = true;
              this.form.attributes[index].is_duplicate = true
              isDuplicate = true
              // throw new Error("duplicate");
              break
            }else{
                if(!isDuplicate == false){
                  this.form.attributes[index].is_duplicate = false
                }
            }
          }
          else{
            // if(!isDuplicate){
            //       this.form.attributes[index].is_duplicate = false
            //       this.form.attributes[index].bgRow = "";
            //     }
          }
        }
    },
    async getExistingBuilding(value, index) {
      let response = "";
      let building_id = "";
      let day_id = "";
      let time_id = [];

      if (this.form.attributes.length > 0) {
        building_id = value.building_id;
        day_id = value.day_id;
        time_id = value.time_id.toString();

        this.checkTimeDuplicate(value, index)
        this.checkTeacherDuplicate(value, index)
        // for (let d = 0; d < this.form.attributes.length; d++) {
        //   let attr = this.form.attributes[d];
        //   if (d != index) {
        //     if (
        //       attr.building_id == value.building_id &&
        //       attr.day_id == value.day_id &&
        //       this.checkTimeSelected(attr.time_id, value.time_id) == true
        //     ) {
        //       this.$toast.error("Duplikat input ruang pada jam yang sama");
        //       this.form.attributes[index].bgRow = "table-danger";
        //       this.isConflict = true;
        //       throw new Error("duplicate");
        //     }
        //     if (
        //       attr.day_id == value.day_id &&
        //       this.checkTimeSelected(attr.time_id, value.time_id) == true &&
        //       this.checkTeacherSelected(attr.teacher_id, value.teacher_id) ==
        //         true
        //     ) {
        //       this.form.attributes[index].bgRow = "table-danger";
        //       this.$toast.error("Duplikat input dosen pada jam yang sama");
        //       this.isConflict = true;
        //       throw new Error("duplicate");
        //     }
        //   }
        // }
        // building_id = this.form.attributes.map(function (element) {
        //   return element.building_id;
        // });
        // day_id = this.form.attributes.map(function (element) {
        //   return element.day_id;
        // });
        // for (let a = 0; a < this.form.attributes.length; a++) {
        //   console.log("time", this.form.attributes[a].time_id);
        //   time_id = [...time_id, ...this.form.attributes[a].time_id];
        // }
      }
      let filterParams = `building_id=${building_id}&day_id=${day_id}&time_id=${time_id.toString()}`;
      if (this.purpose == "edit") {
        filterParams += `&purpose=edit&current_id=${this.$route.params.id}`;
      }
      if (building_id != "" && day_id != "") {
        let response = await module.paginate(
          "api/schedule-attributes/exsiting-building",
          `?${filterParams}`
        );
        // let pagination = response.meta.pagination;
        this.itemBuilding = response.data;

        if(this.itemBuilding.length == 0){
          if(this.form.attributes[index].is_duplicate == false){
                this.isConflict = false;
                this.form.attributes[index].bgRow = "";
              }
        }

        for (let a = 0; a < response.data.length; a++) {
          for (let b = 0; b < response.data[a].times.length; b++) {
            if (response.data[a].times[b].is_conflict) {
              this.isConflict = true;
              this.$toast.error("Konflik Jadwal Ruang");

              this.form.attributes[index].bgRow = "table-danger";
              throw new Error("conflict");
            } else {
              if(this.form.attributes[index].is_duplicate == false){
                this.isConflict = false;
                this.form.attributes[index].bgRow = "";
              }
            }
          }
        }
      }

      // if(this.isConflict){
      //   this.form.attributes[index].bgRow = "table-danger";
      // }else{
      //   this.form.attributes[index].bgRow = "";
      // }
    },
    async getExistingTeacher(value, index) {
      let response = "";
      let teacher_id = "";
      let day_id = "";
      let time_id = [];

      if (this.form.attributes.length > 0) {
        teacher_id = value.teacher_id.toString();
        day_id = value.day_id;
        time_id = value.time_id.toString();

        // teacher_id = this.form.attributes.map(function (element) {
        //   return element.teacher_id;
        // });
        // day_id = this.form.attributes.map(function (element) {
        //   return element.day_id;
        // });
        // for (let a = 0; a < this.form.attributes.length; a++) {
        //   console.log("time", this.form.attributes[a].time_id);
        //   // time_id.concat(this.form.attributes[a].time_id)
        //   time_id = [...time_id, ...this.form.attributes[a].time_id];
        // }
      }

      let filterParams = `teacher_id=${teacher_id}&day_id=${day_id}&time_id=${time_id.toString()}`;
      if (this.purpose == "edit") {
        filterParams += `&purpose=edit&current_id=${this.$route.params.id}`;
      }
      if (teacher_id != "" && day_id != "") {
        let response = await module.paginate(
          "api/schedule-attributes/exsiting-teacher",
          `?${filterParams}`
        );
        // let pagination = response.meta.pagination;
        this.itemTeacher = response.data;

        if(this.itemTeacher.length == 0){
          if(this.form.attributes[index].is_duplicate == false){
                this.isConflict = false;
                this.form.attributes[index].bgRow = "";
              }
        }

        for (let a = 0; a < response.data.length; a++) {
          for (let b = 0; b < response.data[a].times.length; b++) {
            if (response.data[a].times[b].is_conflict) {
              this.isConflict = true;
              // const Toast = Swal.mixin({
              //   toast: true,
              //   position: "top-end",
              //   showConfirmButton: false,
              //   timer: 3000,
              //   timerProgressBar: true,
              //   didOpen: (toast) => {
              //     toast.addEventListener("mouseenter", Swal.stopTimer);
              //     toast.addEventListener("mouseleave", Swal.resumeTimer);
              //   },
              // });

              // Toast.fire({
              //   icon: "error",
              //   title: "Konflik Jadwal Kuliah",
              // });

              this.$toast.error("Konflik Jadwal Dosen");

              this.form.attributes[index].bgRow = "table-danger";
              throw new Error("conflict");
            } else {
              if(this.form.attributes[index].is_duplicate == false){
                this.isConflict = false;
                this.form.attributes[index].bgRow = "";
              }
            }
          }
        }
      }
    },
    addAttributes() {
      let attributes = {
        building_id: 0,
        day_id: 0,
        time_id: [],
        teacher_id: [],
        class_type_id: 0,
        is_duplicate: false,
      };
      let clone = { ...attributes };
      this.form.attributes.push(clone);
    },
    setAttribute(value, index) {
      this.form.attributes[index] = value;
      this.getExistingBuilding(value, index);
      this.getExistingTeacher(value, index);
    },
    deleteAttribute(index) {
      if (this.form.attributes[index].hasOwnProperty("id")) {
        this.scheduleAttributeDeleted.push(this.form.attributes[index].id);
      } 
      this.form.attributes.splice(index, 1);
    },
    async getStudyProgramOption() {
      let response = await module.setTreeSelect("api/study-programs");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.study_programs = response.data;
        this.study_programs.unshift({
          label: "Pilih Program Studi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterGroupOption() {
      let response = await module.setTreeSelect("api/semester-groups");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semester_groups = response.data;
        this.semester_groups.unshift({
          label: "Pilih Kelompok Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSchoolYearOption() {
      let response = await module.setTreeSelect("api/school-years");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.school_years = response.data;
        this.school_years.unshift({
          label: "Semua Angkatan",
          id: 0,
        });
        this.school_years.unshift({
          label: "Pilih Angkatan",
          id: "",
          isDisabled: true,
        });
      }
    },
    chooseDataSubject(value) {
      this.$bvModal.hide("modal-subject");
      this.form.subject_id = value.id;
      this.display.subject_name =
        value.code + " - " + value.name + " - " + value.sks;
    },
    async formOnsubmit() {
      // this.form.attribute = JSON.stringify(this.form.attributes)
      this.form.attribute = this.form.attributes;
      if (this.purpose == "edit" && this.scheduleAttributeDeleted.length > 0) {
        this.form.scheduleAttributeDeleted = this.scheduleAttributeDeleted;
      }
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        //   this.form.attributes = JSON.parse(this.form.attributes)
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/schedules/list");
        // this.form = {};
      }
    },

    // Form Attribute
    async getBuildingOption() {
      let response = await module.setTreeSelectRoom("api/rooms?page_size=1000");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.buildings = response.data;
        this.buildings.unshift({
          label: "Pilih Gedung",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getDayOption() {
      let response = await module.setTreeSelect("api/days");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.days = response.data;
        this.days.unshift({
          label: "Pilih Hari",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getTimeOption() {
      let response = await module.setTreeSelectTime("api/times");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.times = response.data;
        this.times.unshift({
          label: "Pilih Jam",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        `api/users?role_id=1&page_size=500`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getClassTypeOption() {
      let response = await module.setTreeSelect("api/class-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_types = response.data;
        this.class_types.unshift({
          label: "Pilih Kelas",
          id: 0,
          isDisabled: true,
        });
      }
    },
  },
  mounted() {
    this.getStudyProgramOption();
    this.getSemesterOption();
    this.getSchoolYearOption();
    this.getSemesterGroupOption();

    this.getBuildingOption();
    this.getDayOption();
    this.getTimeOption();
    this.getClassTypeOption();
    this.getTeacherOption();

    this.getSemesterActive()
  },
  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.display.subject_name = this.form.subject_name;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nowrap {
  white-space: nowrap;
}
</style>

<style>
.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}
</style>